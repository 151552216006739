import React, { ReactElement, FC, useState } from "react"
import { Row, Col, Typography, Tabs } from "antd"
import ReactMarkdown from "react-markdown"
import { StaticImage } from "gatsby-plugin-image"

import { I_Tab } from "../../global/interfaces"

import "./how-works.less"

const { Title } = Typography
const { TabPane } = Tabs

interface I_Props {
  title: string
  subtitle: string
  text: string
  tabs: I_Tab[]
}

enum Image {
  Search = "0",
  Compare = "1",
  Buy = "2",
}

const HowWorksComponent: FC<I_Props> = ({
  title,
  subtitle,
  text,
  tabs,
}: I_Props): ReactElement => {
  const [image, setImage] = useState("0")
  return (
    <section className="how-works">
      <div className="how-works__wrapper">
        <Row gutter={40}>
          <Col xs={0} lg={12} className="how-works__image">
            {image === Image.Search && (
              <StaticImage src="../../assets/how-search.png" alt={title} />
            )}
            {image === Image.Compare && (
              <StaticImage src="../../assets/how-compare.png" alt={title} />
            )}
            {image === Image.Buy && (
              <StaticImage src="../../assets/how-buy.png" alt={title} />
            )}
          </Col>
          <Col xs={24} lg={12} className="how-works__col-right">
            <Title className="how-works__title">{title}</Title>
            <Title level={2} className="how-works__subtitle">
              {subtitle}
            </Title>
            <ReactMarkdown children={text} />
            <Tabs defaultActiveKey="0" onChange={setImage}>
              {tabs.map(({ title, text }: I_Tab, index: number) => (
                <TabPane tab={title} key={index}>
                  {text}
                </TabPane>
              ))}
            </Tabs>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default HowWorksComponent
