import React, { ReactElement, FC, useState } from "react"
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Upload,
  notification,
  Typography,
} from "antd"
import { CloudUploadOutlined } from "@ant-design/icons"
import { navigate } from "gatsby"
import ReactMarkdown from "react-markdown"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import { I_SearchForm, I_Radio, I_Option } from "../../global/interfaces"
import { SearchType, NotificationPlacement } from "../../global/enums"
import {
  requiredRule,
  nameValidator,
  isbnValidator,
} from "../../global/utils/form"

import "./search-book.less"

const { Group } = Radio
const { Option } = Select
const { useForm, Item } = Form
const { TextArea } = Input
const { Title } = Typography

enum SearchPage {
  EN = "search",
  ES = "busqueda",
  IT = "ricerca",
}

const SearchBookComponent: FC<I_SearchForm> = ({
  searchText,
  uploadText,
  searchTypeInput,
  authorInput,
  titleInput,
  isbnInput,
  countrySelect,
  uploadInput,
  searchButton,
  baseUrl,
  errorNotification,
}: I_SearchForm): ReactElement => {
  const [, country, language] = baseUrl.split("/")

  const [form] = useForm()
  const initialValues = {
    type: searchTypeInput[0].value,
    countryUpload: country || countrySelect.options[1].value,
    countrySearch: country || countrySelect.options[1].value,
    author: "",
    title: "",
  }
  const [searchType, setSearchType] = useState(initialValues.type)
  const selectCountryIsVisible = !country && countrySelect

  const redirectToSearchPage = (queryParams: string) => {
    const urlLanguage = language ?? country
    const url = country ? (language ? baseUrl : `${baseUrl}/${country}`) : ""
    const searchPage =
      urlLanguage === "es"
        ? SearchPage.ES
        : urlLanguage === "it"
        ? SearchPage.IT
        : SearchPage.EN
    navigate(`${url}/${searchPage}?${queryParams}`)
  }

  const handleOnFinish = (formData: any) => {
    const { type } = formData
    const countrySearch = form.getFieldValue("countrySearch")
    let queryParams = `type=${type}&country=${countrySearch}`

    if (type === SearchType.Author) {
      queryParams += `&value=${formData.author}`
    }

    if (type === SearchType.Title) {
      queryParams += `&value=${formData.title}`
    }

    if (type === SearchType.ISBN) {
      const isbns = formData.isbn
        .split(/[ ,.\n]+/)
        .map((isbn: string) => isbn.replace(/-/g, ""))
      queryParams += `&value=${isbns.join(",")}`
    }

    trackCustomEvent({
      category: "Search Book Button",
      action: "Click",
      label: queryParams,
    })
    redirectToSearchPage(queryParams)
  }

  const processUploadedFile = (file: any) => {
    const reader: any = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = async () => {
      if (reader && reader.result) {
        const fileType = file.type.split("/")[1].toUpperCase()
        const formData = new FormData()
        const headers = new Headers()
        headers.append("apikey", process.env.GATSBY_OCR_API_KEI || "")
        formData.append("base64Image", reader.result)
        formData.append("language", "eng")
        formData.append("filetype", fileType)
        formData.append("isTable", "true")
        formData.append("OCREngine", "1")

        try {
          const response = await fetch(process.env.GATSBY_OCR_API_URL || "", {
            method: "POST",
            headers,
            body: formData,
          })

          const responseData = await response.json()
          if (responseData?.ParsedResults[0]?.ParsedText) {
            const isbns = responseData?.ParsedResults[0]?.ParsedText.match(
              /[\d-]+/g
            )
              .map((code: string) => code.replace(/-/g, ""))
              .filter(
                (code: string) => code.length === 13 || code.length === 10
              )
            let queryParams = `type=isbn&value=${isbns.join(",")}`

            if (!selectCountryIsVisible) {
              queryParams += `&country=${form.getFieldValue("countryUpload")}`
            }
            trackCustomEvent({
              category: "Search Book OCR Button",
              action: "Click",
              label: queryParams,
            })
            redirectToSearchPage(queryParams)
          } else {
            notification.error({
              ...errorNotification,
              placement: NotificationPlacement.BottomRight,
            })
          }
        } catch (err) {
          notification.error({
            ...errorNotification,
            placement: NotificationPlacement.BottomRight,
          })
          console.error(err)
        }
      }
    }
  }

  return (
    <Form
      form={form}
      initialValues={initialValues}
      className="search-book"
      onFinish={handleOnFinish}
    >
      <Upload
        className="search-book__upload"
        accept="image/*,.pdf"
        showUploadList={false}
        customRequest={() => null}
        beforeUpload={processUploadedFile as any}
      >
        <div className="search-book__upload-content">
          <CloudUploadOutlined className="search-book__upload-icon" />
          <div>
            <Title level={5}>{uploadInput.text}</Title>
            <ReactMarkdown children={uploadText} />
          </div>
        </div>
      </Upload>
      <ReactMarkdown children={searchText} />
      <Item name="type">
        <Group
          onChange={({ target }) => setSearchType(target.value)}
          value={initialValues.type}
        >
          {searchTypeInput.map(({ id, text, value }: I_Radio) => (
            <Radio key={id} value={value}>
              {text}
            </Radio>
          ))}
        </Group>
      </Item>
      {searchType === "isbn" && (
        <Item
          name={isbnInput.name}
          rules={[
            requiredRule(isbnInput.error),
            { validator: (_, value) => isbnValidator(value, isbnInput.error) },
          ]}
        >
          <TextArea
            name={isbnInput.name}
            placeholder={isbnInput.placeholder}
            autoSize={{ minRows: 3 }}
            className="search-book__textArea"
          />
        </Item>
      )}
      {searchType === "author" && (
        <Item
          name={authorInput.name}
          rules={[
            requiredRule(authorInput.error),
            {
              validator: (_, value) => nameValidator(value, authorInput.error),
            },
          ]}
        >
          <Input
            name={authorInput.name}
            placeholder={authorInput.placeholder}
          />
        </Item>
      )}
      {searchType === "title" && (
        <Item
          name={titleInput.name}
          rules={[
            requiredRule(titleInput.error),
            { validator: (_, value) => nameValidator(value, titleInput.error) },
          ]}
        >
          <Input name={titleInput.name} placeholder={titleInput.placeholder} />
        </Item>
      )}
      <div className="search-book__buttons-search">
        {selectCountryIsVisible && (
          <Item name="countrySearch" className="search-book__select">
            <Select placeholder={countrySelect.label}>
              {countrySelect.options.map(({ id, text, value }: I_Option) => (
                <Option key={id} value={value}>
                  {text}
                </Option>
              ))}
            </Select>
          </Item>
        )}
        <Button
          type="primary"
          htmlType="submit"
          className="search-book__submit"
          // disabled={isSubmitDisabled}
        >
          {searchButton.text}
        </Button>
      </div>
    </Form>
  )
}

export default SearchBookComponent
