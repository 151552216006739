import React, { ReactElement, FC } from "react"
import { Row, Col, Typography } from "antd"
import { StaticImage } from "gatsby-plugin-image"

import SearchBook from "../search-book/search-book"
import { I_SearchForm } from "../../global/interfaces"

import "./hero.less"

const { Title } = Typography

interface I_Props {
  title: string
  subtitle: string
  searchForm: I_SearchForm
  baseUrl: string
}

const HeroComponent: FC<I_Props> = ({
  title,
  subtitle,
  searchForm,
  baseUrl,
}: I_Props): ReactElement => {
  return (
    <section className="hero">
      <Row className="hero__wrapper">
        <Col xs={24} lg={12} xl={12} className="hero__col-left">
          <Title className="hero__title">{title}</Title>
          <Title level={2} className="hero__subtitle">
            {subtitle}
          </Title>
          <SearchBook {...searchForm} baseUrl={baseUrl} />
        </Col>
        <Col xs={0} lg={12} xl={12} className="hero__col-right">
          <StaticImage src="../../assets/search.png" alt={title} />
        </Col>
      </Row>
      <StaticImage src="../../assets/curves_01.png" alt="" />
    </section>
  )
}

export default HeroComponent
