import React, { ReactElement, FC } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"
import CountryLanguageBanner from "../components/country-language-banner/country-language-banner"
import CookiesBanner from "../components/cookies-banner/cookies-banner"
import Hero from "../components/hero/hero"
import Stores from "../components/stores/stores"
import HowWorks from "../components/how-works/how-works"
import {
  I_CountryLanguage,
  I_Navigation,
  I_SearchForm,
  I_Store,
  I_Tab,
  I_Footer,
  I_CookiesBanner,
  I_SEO,
  I_Location,
} from "../global/interfaces"

interface I_Strapi {
  countryLanguage: I_CountryLanguage
  navigation: I_Navigation
  searchForm: I_SearchForm
  stores: I_Store[]
  footer: I_Footer
  cookiesBanner: I_CookiesBanner
}

interface I_PageContext {
  title: string
  storesTitle: string
  howTitle: string
  reviewsTitle: string
  storesText: string
  howText: string
  reviewsText: string
  subtitle: string
  howSubtitle: string
  howTabs: I_Tab[]
  locale: string
  seo: I_SEO
  baseUrl: string
}
interface I_Props {
  pageContext: I_PageContext
  data: {
    strapi: I_Strapi
  }
  location: I_Location
}

const Home: FC<I_Props> = ({
  pageContext,
  data,
  location,
}: I_Props): ReactElement => {
  const {
    countryLanguage,
    navigation,
    searchForm,
    stores,
    footer,
    cookiesBanner,
  } = data.strapi
  const {
    title,
    storesTitle,
    howTitle,
    storesText,
    howText,
    subtitle,
    howSubtitle,
    howTabs,
    baseUrl,
  } = pageContext

  return (
    <>
      <SEO {...pageContext.seo} baseUrl={baseUrl} />
      <CookiesBanner {...cookiesBanner} />
      <CountryLanguageBanner {...countryLanguage} baseUrl={baseUrl} />
      <Layout
        navigation={navigation}
        footer={footer}
        location={location}
        baseUrl={baseUrl}
      >
        <Hero
          title={title}
          subtitle={subtitle}
          searchForm={searchForm}
          baseUrl={baseUrl}
        />
        <Stores title={storesTitle} text={storesText} stores={stores} />
        <HowWorks
          title={howTitle}
          subtitle={howSubtitle}
          text={howText}
          tabs={howTabs}
        />
      </Layout>
    </>
  )
}

export default Home

export const pageQuery = graphql`
  query HomePageQuery($locale: String) {
    strapi {
      countryLanguage(locale: $locale) {
        text
        countryOptions {
          label
          options {
            id
            text
            value
          }
        }
        languageOptions {
          label
          options {
            id
            text
            value
          }
        }
        button {
          text
        }
      }
      navigation(locale: $locale) {
        links {
          id
          link
          target
          text
        }
      }
      searchForm(locale: $locale) {
        uploadText
        searchText
        searchTypeInput {
          id
          text
          value
        }
        searchButton {
          name
          text
        }
        uploadInput {
          name
          text
        }
        countrySelect {
          label
          options {
            id
            text
            value
          }
        }
        authorInput {
          label
          placeholder
          error {
            id
            type
            message
          }
          type
          name
        }
        titleInput {
          label
          placeholder
          error {
            id
            type
            message
          }
          type
          name
        }
        isbnInput {
          id
          label
          placeholder
          error {
            id
            type
            message
          }
          type
          name
        }
        errorNotification {
          message
          description
        }
      }
      stores(locale: $locale) {
        id
        link {
          text
          link
          id
          target
        }
        logo {
          url
        }
        name
      }
      footer(locale: $locale) {
        copyright
        linkList {
          id
          title
          links {
            id
            text
            link
            target
          }
        }
      }
      cookiesBanner(locale: $locale) {
        title
        text
        acceptButton {
          text
        }
      }
    }
  }
`
