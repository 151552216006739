import React, { ReactElement, FC } from "react"
import { Row, Col, Typography } from "antd"
import ReactMarkdown from "react-markdown"

import Link from "../link/link"
import { I_Store } from "../../global/interfaces"

import "./stores.less"

const { Title } = Typography

interface I_Props {
  title: string
  text: string
  stores: I_Store[]
}

const StoresComponent: FC<I_Props> = ({
  title,
  text,
  stores,
}: I_Props): ReactElement => {
  return (
    <section className="stores">
      <div className="stores__wrapper">
        <Row justify="center">
          <Col xs={24} lg={14}>
            <Title level={2} className="stores__title">
              {title}
            </Title>
          </Col>
          <Col xs={24} lg={14}>
            <ReactMarkdown children={text} className="stores__text" />
          </Col>
        </Row>
        <Row justify="center" gutter={24} className="stores__logos">
          {stores.map(({ id, link, name, logo }: I_Store) => (
            <Col xs={12} md={6} lg={4} key={id}>
              <Link
                link={link.link}
                target={link.target}
                className="stores__link"
              >
                <img src={logo.url} alt={name} width="80%" />
              </Link>
            </Col>
          ))}
        </Row>
      </div>
    </section>
  )
}

export default StoresComponent
